import React from 'react'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import Top from './Top';
import Bottom from './Bottom';

const GameBoard = (props) => {

    var tableItems = props.scoreTable.map((item) => {
        var bidString = item.bid;
        bidString = bidString.replace("Spades","♠");
        bidString = bidString.replace("Clubs","♣");
        bidString = bidString.replace("Hearts","♥");
        bidString = bidString.replace("Diamonds","♦");
        return (
            <tr key={item.username}>
                <td>{item.username}</td>
                <td>{bidString}</td>
                <td>{item.numGames}/{item.numWins}/{item.numPoints}</td>
                <td>
                    <span className={item.handWins[0] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[0]}</span>
                    <span className={item.handWins[1] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[1]}</span>
                    <span className={item.handWins[2] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[2]}</span>
                    <span className={item.handWins[3] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[3]}</span>
                    <span className={item.handWins[4] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[4]}</span>
                    <span className={item.handWins[5] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[5]}</span>
                    <span className={item.handWins[6] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[6]}</span>
                    <span className={item.handWins[7] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[7]}</span>
                    <span className={item.handWins[8] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[8]}</span>
                    <span className={item.handWins[9] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[9]}</span>
                    <span className={item.handWins[10] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[10]}</span>
                    <span className={item.handWins[11] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[11]}</span>
                    <span className={item.handWins[12] === "T" ? "w3-badge w3-red" : "w3-badge w3-green"}>{item.handWins[12]}</span>
                </td>
            </tr>
        );
    });

    return (
        <div>

            <Top username={"(" + props.username + ")"} />


            {/** Center content at the middle of the page */}
            <div className="w3-content  w3-light-grey" style={{ maxwidth: "1200px" }} >
                <div className="w3-display-container" style={{ marginTop: "50px", height: "500px", width: "1000px" }}>
                    {/* This is the playing board*/}
                    <div className="w3-display-left">
                        <div className="w3-display-container" style={{ height: "500px", width: "500px", display: `${props.gameTableDisplay}` }}>
                            <img src="/cards/hand.jpg" alt="hand" className="w3-image w3-opacity-max" style={{ height: "500px", width: "500px" }} />

                            <div className="w3-display-topleft">
                                <span className="w3-badge">{props.requestBoardData.usScore}</span> US ({props.username})<br />
                                <span className="w3-badge">{props.requestBoardData.themScore}</span> THEM                    </div>
                            <div className="w3-display-topright"><AmplifySignOut></AmplifySignOut></div>
                            <div className="w3-display-bottomright">Bid: {props.requestBoardData.bid}</div>
                            <div className="w3-display-left"><p className="w3-center w3-orange">LEFT</p></div>
                            <div className="w3-display-right"><p className="w3-center w3-orange">RIGHT</p></div>
                            <div className="w3-display-middle">
                                <div className="w3-display-container" style={{ height: "300px", width: "300px" }}>
                                    <div className="w3-display-left"><img src={"/cards/" + props.requestBoardData.leftCardURL + ".gif"} style={{ visibility: `${props.requestBoardData.leftCardVisibility}` }} alt="Card1" /></div>
                                    <div className="w3-display-right"><img src={"/cards/" + props.requestBoardData.rightCardURL + ".gif"} style={{ visibility: `${props.requestBoardData.rightCardVisibility}` }} alt="Card1" /></div>
                                    <div className="w3-display-topmiddle w3-hide-small"><img src={"/cards/" + props.requestBoardData.partnerCardURL + ".gif"} style={{ visibility: `${props.requestBoardData.partnerCardVisibility}` }} alt="Card1" /></div>
                                    <div className="w3-display-bottommiddle w3-hide-small"   ><img src={"/cards/" + props.requestBoardData.myCardURL + ".gif"} style={{ visibility: `${props.requestBoardData.myCardVisibility}` }} alt="Card1" /></div>
                                    <div className="w3-display-middle">{props.instructions}<br/>
                                        <div className="w3-light-grey">
                                            <div className={"w3-container "+props.instructionsPercentageColor+" w3-center  w3-text-black"} style={{padding:"0px", width:`${props.instructionsPercentage}`}}>{props.instructionsPercentage}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w3-display-topmiddle w3-hide-small"><p className="w3-center w3-orange">PARTNER</p></div>
                            <div className="w3-display-bottommiddle w3-hide-small"><p className="w3-center w3-orange">ME</p></div>
                        </div>
                    </div> 

                    <div className="w3-display-right">

                        <div className="w3-display-container ">
                            <div className="w3-display-top" style={{ height: "340px", width: "500px" }}>
                                <p className="w3-center">Leaderboard - {props.scoreTable.length} player(s)</p>
                                <div className="w3-responsive">
                                    <table className="w3-table-all w3-small">
                                        <tbody>
                                            <tr>
                                                <th>Name</th>
                                                <th>Bid</th>
                                                <th>Gms/Wins/Pts</th>
                                                <th>Tricks</th>
                                            </tr>
                                            {tableItems}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="w3-display-bottom">
                                <textarea id="chathistoryid" className="w3-input w3-border w3-small" style={{ height: "95px", width: "500px", resize: "none" }} readOnly></textarea>
                                <input className="w3-input w3-border" placeholder="Enter chat and hit enter"
                                    onChange={(e) => props.onChangeForChatInputText(e)}
                                    onKeyPress={(e) => props.keyPressed(e)} style={{ height: "40px", width: "500px" }}
                                    value={props.chatText}></input>
                            </div>

                        </div>

                    </div>

                </div>

                <div className={"w3-container" + props.cardsBackgroundColor} style={{ height: "100px", width: "500px", display: `${props.handDisplay}` }}>
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[0] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[0])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[0]}` }} alt="Card1" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[1] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[1])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[1]}` }} alt="Card2" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[2] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[2])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[2]}` }} alt="Card3" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[3] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[3])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[3]}` }} alt="Card4" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[4] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[4])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[4]}` }} alt="Card5" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[5] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[5])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[5]}` }} alt="Card6" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[6] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[6])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[6]}` }} alt="Card7" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[7] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[7])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[7]}` }} alt="Card8" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[8] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[8])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[8]}` }} alt="Card9" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[9] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[9])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[9]}` }} alt="Card10" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[10] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[10])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[10]}` }} alt="Card11" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[11] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[11])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[11]}` }} alt="Card12" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[12] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[12])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[12]}` }} alt="Card13" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[13] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[13])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[13]}` }} alt="Card14" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[14] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[14])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[14]}` }} alt="Card15" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[15] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[15])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[15]}` }} alt="Card16" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[16] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[16])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[16]}` }} alt="Card17" />
                    <img src={"/cards/" + props.requestBoardData.playerCardURLs[17] + ".gif"} onClick={(e) => props.onCardClick(props.requestBoardData.playerCardURLs[17])} style={{ visibility: `${props.requestBoardData.playerCardVisibility[17]}` }} alt="Card18" />
                </div>

                <div className="w3-panel" style={{ width: "500px", display: `${props.bidDisplay}` }}>
                    <span className="w3-large">Please Bid</span><br />
                    <input className="w3-radio" type="radio" name="bid_number" value="3" onChange={(e) => props.onBidNumberClick(3)} /><label>3</label>
                    <input className="w3-radio" type="radio" name="bid_number" value="4" onChange={(e) => props.onBidNumberClick(4)} /><label>4</label>
                    <input className="w3-radio" type="radio" name="bid_number" value="5" onChange={(e) => props.onBidNumberClick(5)} /><label>5</label>
                    <input className="w3-radio" type="radio" name="bid_number" value="6" onChange={(e) => props.onBidNumberClick(6)} /><label>6</label>
                    <input className="w3-radio" type="radio" name="bid_number" value="7" onChange={(e) => props.onBidNumberClick(7)} /><label>7</label><br />
                    <input className="w3-radio" type="radio" name="bid_direction" value="Low" onChange={(e) => props.onBidDirectionClick("Low")} /><label>Low</label>
                    <input className="w3-radio" type="radio" name="bid_direction" value="High" onChange={(e) => props.onBidDirectionClick("High")} /><label>High</label><br />
                    <input className="w3-radio" type="radio" name="bid_suit" value="Clubs" onChange={(e) => props.onBidSuitClick("Clubs")} /><label>Clubs</label>
                    <input className="w3-radio" type="radio" name="bid_suit" value="Diamonds" onChange={(e) => props.onBidSuitClick("Diamonds")} /><label>Diamonds</label>
                    <input className="w3-radio" type="radio" name="bid_suit" value="Hearts" onChange={(e) => props.onBidSuitClick("Hearts")} /><label>Hearts</label>
                    <input className="w3-radio" type="radio" name="bid_suit" value="Spades" onChange={(e) => props.onBidSuitClick("Spades")} /><label>Spades</label><br />
                    <button className="w3-button w3-orange" onClick={props.onBidClick}>Submit Bid</button>
                </div>
                <div className="w3-display-container" style={{ height: "50px", width: "500px", display: `${props.joinButtonDisplay}` }}>
                    <div className="w3-display-middle">
                        <button className="w3-button w3-orange" onClick={(e) => props.onJoinGameClick(e)}>Join Game</button>
                    </div>
                </div>

            </div>

            <Bottom />

        </div>
    );
}

export default GameBoard;