import React from 'react'

const Top = () => {
    return (
        <footer className="w3-container w3-dark-grey" style={{ marginTop: "10px", padding: "8px" }}>
            <div className="w3-content w3-center w3-text-gray">Copyright 2020</div>
        </footer>
    );
}

export default Top;