export function join_game_message(){
    let message = {'action':'join_game'};
    return JSON.stringify(message);
}

export function request_board_message(){
    let message = {'action':'request_board'};
    return JSON.stringify(message);
}

export function bid_message(bidNumber, bidDirection, bidSuit){
    let message = {
        'action':'send_bid',
        'bidNumber':bidNumber,
        'bidDirection':bidDirection,
        'bidSuit':bidSuit
    };
    return JSON.stringify(message);
}

export function send_chat_message(chat){
    let message = {
        'action':'send_chat',
        'chat':chat
    };
    return JSON.stringify(message);
}

export function send_card_message(cardURL){
    let message = {
        'action':'send_card',
        'cardURL':cardURL
    };
    return JSON.stringify(message);
}

export function show_table_message(){
    let message = {'action':'show_table'};
    return JSON.stringify(message);
}

export const requestBoardDataSample = {
    command: "Please wait.... Loading game.",
    myCardURL: "blank",
    myCardVisibility: "hidden",
    leftCardURL: "blank",
    leftCardVisibility: "hidden",
    rightCardURL: "blank",
    rightCardVisibility: "hidden",
    partnerCardURL: "blank",
    partnerCardVisibility: "hidden",
    bidDisplay: "none",
    playerCardURLs: [
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
      "blank",
    ],
    playerCardVisibility: [
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
      "hidden",
    ],
    usScore: 0,
    themScore: 0
  }