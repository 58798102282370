import React, { useEffect, useState } from 'react'
import 'w3-css/w3.css';
import { send_chat_message, show_table_message, join_game_message, request_board_message, bid_message, send_card_message, requestBoardDataSample } from "../util/messages";
import { Auth, Hub } from 'aws-amplify';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useInterval } from "../util/UseInterval";
import Home from "./Home";
import GameBoard from './fragments/GameBoard';

function Game(props) {
    // data from cognito about logged in user
    const [username, setUsername] = useState('');
    const [websocket, setWebsocket] = useState(null);
    const [accessKeyId, setAccessKeyId] = useState('');
    const [secretAccessKey, setSecretAccessKey] = useState('');
    const [sessionToken, setSessionToken] = useState('');
    const [jwtToken, setJwtToken] = useState('');
    // data received from the server about state of the game
    const [requestBoardData, setRequestBoardData] = useState(requestBoardDataSample);
    // save the state of the bid when selected
    const [bidNumber, setBidNumber] = useState(null);
    const [bidDirection, setBidDirection] = useState(null);
    const [bidSuit, setBidSuit] = useState(null);
    // controllers display of UI
    const [bidDisplay, setBidDisplay] = useState("none");
    const [handDisplay, setHandDisplay] = useState("none");
    const [joinButtonDisplay, setJoinButtonDisplay] = useState("none");
    const [gameTableDisplay, setGameTableDisplay] = useState("block");
    // instructions to user
    const [instructions, setInstructions] = useState("Please wait while game is loading...");
    const [instructionsPercentage, setInstructionsPercentage] = useState("0%");
    const [instructionsPercentageColor, setInstructionsPercentageColor] = useState("w3-green");
    // state for game
    const [joinedGame, setJoinedGame] = useState(false);
    const [bidReceived, setBidReceived] = useState(false);
    const [kittyCardsSent, setKittyCardsSent] = useState(0);
    const [cardsPlayed, setCardsPlayed] = useState(0);
    const [loggedIn, setLoggedIn] = useState(false);
    const [requestedCardsForBid, setRequestedCardsForBid] = useState(false);
    const [requestedCardsForKitty, setRequestedCardsForKitty] = useState(false);
    const [lastHand, setLastHand] = useState(0);
    const [cardsBackgroundColor, setCardsBackgroundColor] = useState('');
    const [scoreTable, setScoreTable] = useState([]);
    const [chatText, setChatText] = useState('');

    // game loop interval
    useInterval(gameLoop, 1000);

    useEffect(() => {
        //console.log('useEffect in App.js');

        Hub.listen('auth', (data) => {
            // console.log("App.js heard auth...");
            const { payload } = data;
            onAuthEvent(payload);
        })

        // console.log("App.js useEffect requestBoardData = " + requestBoardData);
    }, []);

    function resetGameState() {
        setInstructions("Please wait while game is loading...");
        setCardsBackgroundColor('');
        setKittyCardsSent(0);
        setCardsPlayed(0);
        setJoinButtonDisplay("none");
        setBidDisplay("none");
        setHandDisplay("none");
        setBidReceived(false);
        setRequestedCardsForBid(false);
        setRequestedCardsForKitty(false);
        setLastHand(0);
        //requestBoardData.myCardVisibility='hidden';
        //requestBoardData.leftCardVisibility='hidden';
        //requestBoardData.rightCardVisibility='hidden';
        //requestBoardData.partnerCardVisibility='hidden';
    }

    function formatWaitClock(seconds) {
        const minPart = ~~(seconds / 60);
        const secRemainder = seconds - minPart * 60;

        var minString = "" + minPart
        var secString = "" + secRemainder;
        if (minPart < 10) {
            minString = "0" + minString;
        }
        if (secRemainder < 10) {
            secString = "0" + secString;
        }

        return minString + ":" + secString;
    }

    function gameLoop() {
        if (loggedIn === false) {
            // console.log("skipping gameLoop, not logged in");
            return;
        }

        var date = new Date();
        const min = date.getMinutes() % 3;
        const sec = date.getSeconds();
        //console.log("min=" + min + " secs=" + sec + " joinedGame=" + joinedGame);

        // waiting for user to join game
        if (min === 0 && sec >=0 && sec < 20 && joinedGame === false) {
            // clear out the scores
            if (sec === 0) {
                scoreTable.splice(0, scoreTable.length);
            }

            resetGameState();
            setInstructions("Hurry! " + formatWaitClock(20 - sec) + " to Join!");
            setJoinButtonDisplay("block");


            // set the percentage for the bar
            setInstructionsPercentage(~~(((20 - sec) / 20) * 100) + "%");
            setInstructionsPercentageColor("w3-red");

        } else if (min === 0 && sec >= 0 && sec < 20 && joinedGame === true) {
            // user clicked join!
            setInstructions("Synchronizing play. Wait " + formatWaitClock(20 - sec) + " for others to join.");
            setJoinButtonDisplay("none");

            // set the percentage for the bar
            setInstructionsPercentage(~~(((20 - sec) / 20) * 100) + "%");
            setInstructionsPercentageColor("w3-green");

        } else if ( ( (min===0 && sec >= 20) || min > 0) && joinedGame === false) {
            // user failed to join game; wait until next time!
            resetGameState();
            setInstructions("Synchronizing play. Wait " + formatWaitClock((2 - min) * 60 + (60 - sec)) + " for previous game.");
            setJoinButtonDisplay("none");

            const tempsecs = (2 - min) * 60 + (60 - sec);
            setInstructionsPercentage(~~((tempsecs / 180) * 100) + "%");
            setInstructionsPercentageColor("w3-green");

        } else if (min === 0 && sec >= 20 && sec < 40 && joinedGame === true && bidReceived === false) {
            // time to bid
            if (requestedCardsForBid === false) {
                websocket.send(request_board_message());
                setRequestedCardsForBid(true);
                //scoreTable.splice(0, scoreTable.length);
            }
            setInstructions("Hurry! " + formatWaitClock(40 - sec) + " to bid!");
            setBidDisplay("block");
            setHandDisplay("block");
            setJoinButtonDisplay("none");

            // set the percentage for the bar
            setInstructionsPercentage(~~(((40 - sec) / 20) * 100) + "%");
            setInstructionsPercentageColor("w3-red");

        } else if (min === 0 && sec >= 20 && sec < 40 && joinedGame === true && bidReceived === true) {
            // received bid in time
            setInstructions("Synchronizing play. Wait " + formatWaitClock(40 - sec) + " for others to bid.");
            setBidDisplay("none");
            setJoinButtonDisplay("none");

            // set the percentage for the bar
            setInstructionsPercentage(~~(((40 - sec) / 20) * 100) + "%");
            setInstructionsPercentageColor("w3-green");

        } else if (min === 0 && sec >= 40 && joinedGame === true && bidReceived === false) {
            // didn't bid in time; wait until next game
            alert("Bid not received in time");
            setJoinedGame(false);
        } else if (min === 0 && sec >= 40 && sec < 60 && joinedGame === true && bidReceived === true && kittyCardsSent < 6) {
            // time to get rid of kitty cards
            setBidDisplay("none");
            setInstructions(formatWaitClock(60 - sec) + " to discard " + (6 - kittyCardsSent) + " kitty cards.");
            setCardsBackgroundColor("w3-orange");
            // time to bid
            if (requestedCardsForKitty === false) {
                websocket.send(request_board_message());
                setRequestedCardsForKitty(true);
            }

            // set the percentage for the bar
            setInstructionsPercentage(~~(((60 - sec) / 20) * 100) + "%");
            setInstructionsPercentageColor("w3-red");

        } else if (min === 0 && sec >= 40 && sec < 60 && joinedGame === true && bidReceived === true && kittyCardsSent === 6) {
            // 6 cards discarded
            setBidDisplay("none");
            setInstructions("Synchronizing play. Wait " + formatWaitClock(60 - sec) + " for others to discard kitty.");
            setCardsBackgroundColor("");


            // set the percentage for the bar
            setInstructionsPercentage(~~(((60 - sec) / 20) * 100) + "%");
            setInstructionsPercentageColor("w3-green");

        } else if (min >= 1 && joinedGame === true && bidReceived === true && kittyCardsSent < 6) {
            // didn't discard in time
            alert("Kitty cards not discarded in time");
            setBidDisplay("none");
            setJoinedGame(false);

        } else if ((min === 1 || min === 2) && joinedGame === true && bidReceived === true && kittyCardsSent === 6) {

            const numSecs = (min - 1) * 60 + sec;
            const handNum = ~~(numSecs / 10);
            const maxSecs = Math.ceil(numSecs / 10) * 10;

            // set the percentage for the bar
            if ((maxSecs - numSecs) > 0) {
                setInstructionsPercentage((maxSecs - numSecs) + "0%");
            } else {
                setInstructionsPercentage((maxSecs - numSecs) + "%");
            }

            // clear out the scores
            if (numSecs % 10 === 0) {
                //scoreTable.splice(0, scoreTable.length);
            }

            // we are in a new hand; refresh the page
            if (lastHand !== handNum) {
                websocket.send(show_table_message());
                setLastHand(handNum);
            }

            if (cardsPlayed === handNum) {
                // waiting to play
                setInstructions("Hurry! " + formatWaitClock(maxSecs - numSecs) + " to play!");
                setCardsBackgroundColor("w3-orange");
                setInstructionsPercentageColor("w3-red");

            } else if (cardsPlayed === handNum + 1) {
                // already played
                setInstructions("Synchronizing Play. Wait " + formatWaitClock(maxSecs - numSecs) + " for others to play.");
                setCardsBackgroundColor("");
                setInstructionsPercentageColor("w3-green");

            } else if (cardsPlayed < handNum + 1) {
                // didn't play in time
                alert("Play not received in time");
                setJoinedGame(false);
            }

        } else {
            console.log("ERROR!!!! didn't handle this condition! min=" + min + " sec=" + sec + " joinedGame=" + joinedGame + " bidReceived=" + bidReceived + " kittyCardsSent=" + kittyCardsSent);
        }
    }

    function onAuthEvent(payload) {
        // console.log("onAuthEvent(payload)=");
        // console.log(payload);

        if (payload.event === 'signIn') {
            // SIGN IN EVENT
            setUsername(payload.data.username);
            setJwtToken(payload.data.signInUserSession.idToken.jwtToken);
            Auth.currentCredentials()
                .then(credentials => {
                    //console.log("credential2s=");
                    //console.log(credentials);
                    setAccessKeyId(credentials.accessKeyId);
                    setSecretAccessKey(credentials.secretAccessKey);
                    setSessionToken(credentials.sessionToken);
                    setLoggedIn(true);


                    // create websocket
                    const websocket = new W3CWebSocket(`wss://3zzhs22rh6.execute-api.us-east-1.amazonaws.com/prod?token=${payload.data.signInUserSession.idToken.jwtToken}`);

                    // set what happens when socket is opened
                    websocket.onopen = (event) => {
                        // console.log('WebSocket websocket Connected');
                        // console.log("onopen event=");
                        // console.log(event);
                        // setLoggedIn(true);
                    };

                    // set what happens when message is received
                    websocket.onmessage = (messageEvent) => {
                        // console.log("onmessage messageEvent");
                        // console.log(messageEvent);
                        let body = JSON.parse(messageEvent.data);
                        if (body.action === 'request_board') {
                            // console.log("messageEvent was requestBoard message.");
                            // console.log("body.myCardURL=" + body.myCardURL);
                            setRequestBoardData(body);

                            // set the number of cards played based on how many cards are in your hand
                            setCardsPlayed(12 - body.playerCardURLs.indexOf("blank"));

                            // make the game reset for next round
                            if (body.playerCardURLs.indexOf("blank") === 0) {
                                setJoinedGame(false);
                            }

                        } else if (body.action === 'join_game_success') {
                            // successfully joined
                            setJoinedGame(true);
                        } else if (body.action === 'send_bid_success') {
                            // successfully sent bid
                            setBidReceived(true);
                        } else if (body.action === 'status_message') {
                            for (let s of scoreTable) {
                                if (s.username === body.username) {
                                    s.bid = body.bid;
                                    s.usScore = body.usScore;
                                    s.themScore = body.themScore;
                                    s.handWins = body.handWins;
                                    return;
                                }
                            }                            // save this score
                            scoreTable.push(body);
                            // console.log("after status_message scoreTable=");
                            // console.log(scoreTable);
                        } else if (body.action === 'send_chat_successful') {
                        } else if (body.action === 'send_chat') {

                            // scroll text
                            var textarea = document.getElementById('chathistoryid');
                            textarea.scrollTop = textarea.scrollHeight;
                            textarea.value = textarea.value + body.message + '\n';

                            //setChatHistory(textarea.value + body.message);

                        } else {
                            console.log("messageEvent was not recognized:" + body.action);
                        }
                    };


                    // set what happens when socket is closed
                    websocket.onclose = () => {
                        //console.log('WebSocket websocket Disonnected');
                        Auth.signOut()
                            .then(data => {
                                setLoggedIn(false);
                                // console.log(data);
                                alert("successful sign out");
                            })
                            .catch(err => {
                                console.log(err);
                                alert("problem signing out")
                            });
                    }
                    setWebsocket(websocket);
                });

        } else if (payload.event === 'signIn_failure') {

        } else if (payload.event === 'signOut') {
            // SIGN OUT EVENT
            window.location.href = "/";
        } else if (payload.event === 'configured') {
        } else {
            console.log(payload.event);
        }
    }



    function onJoinGameClick(e) {
        e.preventDefault();
        websocket.send(join_game_message());
    }

    // click handler for bid
    function onBidClick(e) {
        //console.log("onBidClick(e)=");
        //console.log(e);
        if (!bidNumber) {
            alert("Add Bid Number (3,4,5,6 or 7.");
        } else if (!bidDirection) {
            alert("Add Bid Direction (High or Low).")
        } else if (!bidSuit) {
            alert("Add Bid Suit (Clubs, Diamonds, Hearts or Spades)");
        } else {
            //console.log("Received valid bid!");
            websocket.send(bid_message(bidNumber, bidDirection, bidSuit));
        }
    }

    function onBidNumberClick(e) {
        // console.log("onBidNumberClick(e)=");
        // console.log(e);
        setBidNumber(e);
    }

    function onBidDirectionClick(e) {
        // console.log("onBidDirectionClick(e)=");
        // console.log(e);
        setBidDirection(e);
    }

    function onBidSuitClick(e) {
        // console.log("onBidSuitClick(e)=");
        // console.log(e);
        setBidSuit(e);
    }

    function onChangeForChatInputText(event) {
        setChatText(event.target.value);
    }
    function keyPressed(event) {
        if (event.key === "Enter") {
            if (chatText.length > 0) {

                // send chatText
                websocket.send(send_chat_message(chatText));
                setChatText('');
            }
        }
    }

    function onCardClick(e) {
        var date = new Date();
        const min = date.getMinutes() % 3;
        const sec = date.getSeconds();

        //    console.log("onCardClick()=" + e);
        if (min === 0 && sec >=40 && sec < 60 && joinedGame === true && bidReceived === true && kittyCardsSent < 6) {
            // DISCARDING KITTY CARDS
            websocket.send(send_card_message(e));
            setKittyCardsSent(kittyCardsSent + 1);
        } else {
            // PLAYING CARDS
            const numSecs = (min - 1) * 60 + sec;
            const handNum = ~~(numSecs / 10);
            const maxSecs = Math.ceil(numSecs / 10) * 10;

            if (cardsPlayed === handNum) {
                websocket.send(send_card_message(e));
            }
        }
    }



    if (!loggedIn) {
        return (
            <Home />
        );
    } else {
        return (
            <GameBoard
                requestBoardData={requestBoardData}
                onBidClick={onBidClick}
                onJoinGameClick={onJoinGameClick}
                onBidNumberClick={onBidNumberClick}
                onBidDirectionClick={onBidDirectionClick}
                onBidSuitClick={onBidSuitClick}
                onCardClick={onCardClick}
                keyPressed={keyPressed}
                onChangeForChatInputText={onChangeForChatInputText}

                bidDisplay={bidDisplay}
                handDisplay={handDisplay}
                joinButtonDisplay={joinButtonDisplay}
                gameTableDisplay={gameTableDisplay}
                cardsBackgroundColor={cardsBackgroundColor}
                chatText={chatText}

                setJoinedGame={setJoinedGame}
                joinedGame={joinedGame}
                scoreTable={scoreTable}

                username={username}
                instructions={instructions}
                instructionsPercentage={instructionsPercentage}
                instructionsPercentageColor={instructionsPercentageColor}
            />
        );
    }
}

export default Game;