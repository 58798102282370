import React, { useEffect } from 'react'
import { AmplifySignIn, AmplifySignUp, AmplifyConfirmSignUp, AmplifyAuthenticator } from '@aws-amplify/ui-react'
import 'w3-css/w3.css';
import Top from './fragments/Top';
import Bottom from './fragments/Bottom';

const Home = (props) => {

    useEffect(() => {
        // console.log('useEffect in Home');
    }, []);

    return (
        <div>

            <Top username='' />

            {/** Center content at the middle of the page */}
            <div className="w3-content  w3-light-grey" style={{ maxwidth: "1200px" }} >


                {/** this container allows the cards below to have a padding space */}
                <div className="w3-container">

                    <div className="w3-panel w3-center w3-card w3-orange" style={{ marginTop: "50px" }}><h1>Bid Whist with the SameCards</h1></div>

                    <div className="w3-display-container">
                        <img src="/cards/hand.jpg" alt="hand" className="w3-image w3-opacity-max" style={{ width: "100%" }} />
                        <div className="w3-padding w3-display-topleft w3-text-white">
                            <h3 className="w3-text-black">A Different Way to Play Bid Whist</h3>
                            <p className="w3-text-black">This new way of playing Bid Whist tests who can play the same
                            hand better. Players do not play head-to-head with human partners. Players play in separate games with computer partners, are dealt the same cards,
                            bid based on the same hand and they <b>synchronize</b> their play hand-by-hand. This game is best played with 2+ people.</p>
                            <p className="w3-text-black"><b>Why play this way?</b> There's a lot of subjectivity when trying to
                            determine who is the best player. Who are the partners? Who received the best cards? Who's turn it
                            is to bid? All of those excuses are put to rest when you play using the <b>same cards</b>.</p>
                            
                        </div>

                        <div className="w3-display-middle">
                            <AmplifyAuthenticator>
                                <AmplifySignIn ></AmplifySignIn>
                            </AmplifyAuthenticator>
                        </div>
                    </div>
                </div>

            </div>

            <Bottom />


        </div>
    );
}

export default Home;