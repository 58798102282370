// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9fb17cc0-ff51-4307-90ef-cdc6d0800266",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_L8FgIXnJb",
    "aws_user_pools_web_client_id": "53gh7o8rc75j1gheqdbmusinqk",
    "oauth": {},
    "aws_content_delivery_bucket": "samecards.com-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3f58feva3yajw.cloudfront.net"
};


export default awsmobile;
