import React from 'react'

const Top = (props) => {
    return (
        <div className="w3-top">
            <div className="w3-bar w3-black">
                <button className="w3-bar-item w3-button">Bid Whist with the SameCards {props.username}</button>
            </div>
        </div>
    );
}

export default Top;